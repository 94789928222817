import React, {FunctionComponent} from 'react';
import Layout from 'src/components/Layout';

export type Props = {
  location?: {pathname: string};
};

const NotFoundPage: FunctionComponent<Props> = ({location}) => (
  <Layout location={location}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
